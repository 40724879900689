import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

export default function Memberships() {
	const data = useStaticQuery(graphql`
		{
			ebcc: wpMediaItem(title: { eq: "ebcc_new" }) {
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			sussexChamber: wpMediaItem(title: { eq: "sussex_chamber" }) {
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			WiredSussex: wpMediaItem(title: { eq: "WiredSussex" }) {
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			fsb: wpMediaItem(title: { eq: "fsb" }) {
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			bni: wpMediaItem(title: { eq: "bni" }) {
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
		}
	`);

	const ebcc = data.ebcc.gatsbyImage;
	const sussexChamber = data.sussexChamber.gatsbyImage;
	const WiredSussex = data.WiredSussex.gatsbyImage;
	const fsb = data.fsb.gatsbyImage;
	const bni = data.bni.gatsbyImage;

	const logos = {
		padding: "3em",
		marginTop: "auto",
		marginBottom: "auto",
	};

	return (
		<section className="py-5 pt-lg-7">
			<Container>
				<Row>
					<Col className="">
						<h2 className=" text-primary display-5  mb-5">Memberships</h2>
					</Col>
				</Row>
				<Row>
					<Col md={4} style={logos}>
						{" "}
						<GatsbyImage
							image={ebcc}
							alt="Eastbourne Chamber Of Commerce members logo"
						/>
					</Col>
					<Col className="text-center" md={4} style={logos}>
						<a rel="noopener noreferrer">
							<GatsbyImage
								image={sussexChamber}
								alt="Sussex Chamber of commerce members logo"
							/>
						</a>
					</Col>
					<Col md={4} style={logos} className="text-center">
						<a
							href="https://www.wiredsussex.com/company/1298715/rjm-digital"
							target="_blank"
							rel="noopener noreferrer"
						>
							<GatsbyImage
								image={WiredSussex}
								alt="wired sussex members logo"
								style={{ height: "100%" }}
							/>
						</a>
					</Col>
				</Row>
				<Row className="text-center">
					<Col md={4} style={logos}>
						<GatsbyImage
							image={fsb}
							alt="federation of small businesses members logo"
						/>
					</Col>
					<Col xs={12} md={4} style={logos}>
						<a
							href="https://bnilondon.com/central-london-&-the-city-st-pauls-bni/en-GB/memberdetails?encryptedMemberId=bxq7jLZmNv%2Be4joPIXAh3g%3D%3D&cmsv3=true&name=Robert+Mould"
							target="_blank"
							rel="noopener noreferrer"
						>
							<GatsbyImage image={bni} alt="BNI member logo" />
						</a>
					</Col>
					<Col xs={12} md={4} style={logos}>
						<a
							href="https://digitalagencynetwork.com/agencies/brighton"
							target="_blank"
							rel="noopener noreferrer"
						>
							<StaticImage
								src="../images/dan-member.svg"
								alt="Brighton digital agency network member badge light"
								placeholder="blurred"
								quality="100"
								id="dan-logo"
							/>
						</a>
					</Col>
				</Row>
			</Container>
		</section>
	);
}
